<template>
    <div class="stakewait">
        <!-- notification start -->
        <div class="marquee">
            <Marquee :duration="15">{{ promptvalue }}</Marquee>

        </div>
        <!-- notification end -->
        <div class="coinInfomation">
            <div class="coinImg"><img :src="FG1" class="dailogo" /></div>
            <div class="coinInfo">{{ $t("menu.Flashloan") }}</div>
        </div>
        <Divider style="background: rgba(142, 200, 243, 0.1)" />
        <div class="ownwalletInfo">
            <div class="ownTips">{{ $t("stake.Balance") }}:</div>
            <div class="ownMoney">
                {{ formattedDaiOwnMoney }}<img class="daimi" :src="daimni" />
            </div>
        </div>
        <div class="inputInfo">
            <input v-model.number="daiValue" type="number" class="inputStyle" @input="validateAmount" />
            <button @click="(daiValue = formattedDaiOwnMoney), validateAmount()" class="tbnStyle ripple">
                {{ $t("network.all") }}
            </button>
        </div>


        <!-- <div class="autoProcess">
            <div class="a1">
                <input type="checkbox" checked="checked">
            </div>
            <div class="a2">
                <span> {{ $t("menu.xNumber") }}: {{ 0 }} ，{{ $t("menu.xTips") }}</span>
            </div>
        </div> -->

        <div class="StakeAuthor">
            <div class="Author">
                <!-- appoove button start-->
                <button :disabled="empowerbutton" class="StakeBtn ripple" @click="empower" v-if="typeApprove == 0">
                    {{ $t("stake.Appoove") }}
                </button>
                <button class="StakeBtn" v-if="typeApprove == 1">
                    {{ $t("stake.Appooveing") }}
                </button>
                <!-- appoove button end -->
            </div>

            <div class="Stakeing">


                <button :disabled="depositDisabled" v-if="whiteAddresstype == true" class="StakeBtn ripple"
                    @click="getindex">
                    {{ $t("menu.Launch") }}
                </button>
                <button :disabled="depositDisabled" v-else-if="isStarttype == true && flashAddresstype == false"
                    class="StakeBtn ripple" @click="getindex">
                    {{ $t("menu.Launch") }}
                </button>
                <button disabled="true" v-else class="StakeBtn ripple" @click="getindex">
                    {{ $t("menu.Launch") }}
                    <!-- {{ isStarttype==true&&flashAddresstype==false }}|{{ isStarttype==true }}|{{ flashAddresstype==false }} -->
                </button>
            </div>
        </div>

        <div><img class="bStyle" :src="stylea" alt="" /></div>
        <!--loading start-->
        <div v-if="isloading" class="loading">
            <img :src="loadingimg" class="loadimg" />
        </div>
        <!--loading end-->
    </div>
</template>
  
<style lang="less" scoped>
.ripple {
    position: relative;
    overflow: hidden;
}

.ripple:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-image: radial-gradient(circle, #666 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform .3s, opacity .5s;


}

.ripple:active:after {
    transform: scale(0, 0);
    opacity: .3;
    transition: 0s;
}

.stakewait {
    border-radius: 10px;
    box-shadow: 0px 0px 0px 0.4px rgb(80, 77, 77) inset;
    background-image: linear-gradient(133deg,
            rgba(7, 110, 150, 0.3),
            rgba(132, 2, 72, 0.6));
    box-sizing: border-box;
    width: 330px;
    color: white;
    padding: 40px 20px 20px 20px;
    position: relative;

    .loading {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        border: solid 1px rgba(70, 70, 70, 0.581);
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        backdrop-filter: blur(3px);
        -webkit-backdrop-filter: blur(3px);
        -moz-backdrop-filter: blur(3px);
        -o-backdrop-filter: blur(3px);
        background-color: rgba(0, 0, 0, 0.1);
        opacity: 0.9;
        color: #81c0f1;
        z-index: 10000 !important;
        top: 0;
        left: 0;
        animation: fadeIn 0.3s ease-out forwards;

        .loadimg {
            width: 118px;
            height: 118px;
        }
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    .bStyle {
        width: 290px;
        height: 76px;
    }

    .currP {
        margin-bottom: 20px;
    }

    .autoProcess {
        display: flex;
        justify-content: flex-start;

        .a1 {
            margin-right: 8px;
            height: 15px;

        }

        .a1 input {
            width: 15px;
            height: 15px;
        }

        .a2 {
            height: 15px;
            position: relative;
            width: 100%;

            span {
                position: absolute;
                top: -2px;
            }
        }


        // input {
        //     width: 15px;
        //     height: 15px;
        //     border-radius: 5px;
        // }

        // span {
        //     position: absolute;
        //     top: 0px;
        //     left: 25px;
        // }
    }

    .StakeAuthor {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;

        .StakeBtn {
            width: 136px;
            height: 43px;
            border-radius: 10px;
            border: none;
            outline: none;
            cursor: pointer;
            font-size: 18px;
            color: rgb(2, 0, 0);
            font-size: 20px;
            background-image: linear-gradient(126deg,
                    rgba(142, 200, 243, 0.3),
                    /* Adjust the last value (0.2) to set the opacity of the first color */
                    rgba(250, 51, 158, 0.3));
        }

        .Author {
            width: 136px;
            height: 43px;
        }

        .Stakeing {
            width: 136px;
            height: 43px;
        }
    }

    .hr1 {
        border: none;
        border-top: 1px solid rgba(142, 200, 243, 0.3);
        /* 蓝色线条 */
    }

    .tvl {
        font-size: 10px;
        margin: 5px 0 5px 0;

        span {
            font-weight: 800;
            color: rgba(255, 255, 255, 0.4);
        }
    }

    .StakeInfo {
        display: flex;
        margin-bottom: 10px;

        .StakeResult {
            flex: 1;
            text-align: left;
            font-size: 14px;
            font-weight: 600;
            position: relative;
            top: 6px;
        }

        .StakeMoney {
            text-align: right;
            font-size: 20px;
            font-weight: 600;

            .daimi {
                margin-left: 5px;
                width: 14px;
                height: 12px;
            }
        }
    }

    .inputInfo {
        border: 3px solid #81c0f1;
        height: 53px;
        box-sizing: border-box;
        border-radius: 8px;
        margin: 10px 0;
        display: flex;


        .inputStyle {
            border: none;
            outline: none;
            width: 220px;
            height: 100%;
            font-size: 20px;
            padding: 0 10px;
            color: #81c0f1;
            background-color: transparent;
            box-sizing: border-box;
            border-radius: 8px;

        }

        .tbnStyle {
            flex: 1;
            color: rgb(72, 2, 64);
            border: none;
            outline: none;
            font-size: 20px;
            border-radius: 0px 5px 5px 0px;
            cursor: pointer;
            background: url("../assets/img/Gradient.svg") no-repeat center center/cover;
        }
    }

    .ownwalletInfo {
        font-family: "Poppins", sans-serif;
        display: flex;


        .ownTips {
            font-size: 18px;
            font-weight: 600;
            flex: 1;
        }

        .ownMoney {
            font-size: 20px;

            img.daimi {
                width: 14px;
                height: 12px;
                margin-left: 5px;
            }
        }
    }

    .marquee {
        margin-bottom: 15px;
        color: #81c0f1;
    }

    .coinInfomation {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;

        .coinImg {
            width: 29px;
            height: 29px;

            .dailogo {
                width: 29px;
                height: 29px;
            }
        }

        .coinInfo {
            font-size: 18px;
            font-family: "Poppins", sans-serif;
            position: relative;
            top: 0px;
            left: 10px;
            font-weight: bold;
        }
    }
}
</style>
<script>
import ProgressBar from "@/components/ProgressBar.vue";
import Marquee from "@/components/Marquee.vue";
import web3Utils from "@/utils/web3Utils.js";
import dai from "@/assets/img/DaiStablecoin.svg";
import loadingimg from "@/assets/img/loading.svg";
import FG1 from "@/assets/img/FG1.svg";
import daimni from "@/assets/img/daimini.svg";
import stylea from "@/assets/img/stylea.png";
import contract from "@/plugins/contract.js";
import siteapi from "@/json/siteapi.json";
import Plugens from "@/plugins/index";
import loan from "@/plugins/loan.js";
import Web3 from "web3";
import axios from "axios";
import sign from "@/plugins/sign.js";
export default {
    data() {
        return {
            isButtonDisabled: true,
            depositDisabled: true,
            typeApprove: 0,
            typedeposit: 0,
            dai: dai,
            FG1: FG1,
            daimni: daimni,
            loadingimg: loadingimg,
            daiValue: "100",
            daiOwnMoney: 0,
            stylea: stylea,
            stakedMoney: 0,
            currentProgress: 0,
            usersdata: {},
            sitedata: {},
            addresscode: "",
            empowerbutton: true,
            needinvest: "",
            Minimal: 0,
            isloading: false,
            whiteAddresstype: false,
            isStarttype: false,
            flashAddresstype: true,
            returndatadata: "",
            promptvalue: ""
        };
    },
    components: {
        Marquee,
        //ProgressBar,
    },
    async mounted() {
        // Determine whether the wallet is connected
        if (typeof window.ethereum !== "undefined") {
            //console.log("Connected wallet");

            // Async obtaining network information
            window.ethereum
                .request({ method: "eth_chainId" })
                .then((chainId) => {
                    // is Polygon?
                    if (chainId === "0x89") {
                        // console.log("The current network is a Polygon chain");
                        // this.$router.push({path:'/EvStake'});
                    } else {
                        //  console.log("The current network is not Polygon chain");
                        this.$router.push({ path: "/" });
                        return;
                    }
                })
                .catch((error) => {
                    console.error("Failed to obtain network information. Procedure:", error);
                });
        } else {
            //console.log("Unconnected wallet");
        }
        this.sitedata = siteapi;
        const currentAddress = await web3Utils.connectWallet();
        // console.log(currentAddress);
        this.addresscode = currentAddress;
        if (currentAddress) {
            this.daiOwnMoney = parseFloat(
                await web3Utils.getDaiBalance(this.addresscode)
            );
            console.log(this.daiOwnMoney)
            //  console.log(this.daiOwnMoney.toFixed(6));

            this.signtype()
            // this.exchangeallowance()
        }
    },
    methods: {
        // 签名验证
        async signtype() {
            this.isloading = true;
            await sign
                .getauthorization(
                    this.addresscode
                )
                .then((res) => {
                    console.log(res, '签名验证')
                    if (res.code == 0) {
                        this.users();
                        this.exchangeallowance();
                        this.limitAllInvestAmount();
                        this.minInvestAmount();
                        this._whitesAddress()
                        this.isStart()
                        this._flashAddress()
                    }
                    this.isloading = false;
                });
        },
        async _whitesAddress() {
            let _decimals = 18;
            let ContractAddress;
            ContractAddress = this.sitedata.loan_token.address;
            await loan
                ._whitesAddress(
                    this.addresscode,
                    ContractAddress,
                    _decimals,
                )
                .then((res) => {
                    console.log(res, '_whitesAddress')
                    this.whiteAddresstype = res.data
                });
        },
        async isStart() {
            let _decimals = 18;
            let ContractAddress;
            ContractAddress = this.sitedata.loan_token.address;
            await loan
                .isStart(
                    this.addresscode,
                    ContractAddress,
                    _decimals,
                )
                .then((res) => {
                    console.log(res, 'isStart')
                    this.isStarttype = res.data
                    console.log(this.isStarttype)
                });
        },
        async _flashAddress() {
            let _decimals = 18;
            let ContractAddress;
            ContractAddress = this.sitedata.loan_token.address;
            await loan
                ._flashAddress(
                    this.addresscode,
                    ContractAddress,
                    _decimals,
                )
                .then((res) => {
                    console.log(res, '_flashAddress')
                    this.flashAddresstype = res.data
                    if (this.flashAddresstype) {
                        this.promptvalue = this.$t("network.flashloansError")
                    } else {
                        this.promptvalue = this.$t("network.flashloansOnce")
                    }
                });
        },
        async getdata() {
            const currentAddress = await web3Utils.connectWallet();
            // console.log(currentAddress);
            this.addresscode = currentAddress;
            if (currentAddress) {
                this.daiOwnMoney = parseFloat(
                    await web3Utils.getDaiBalance(this.addresscode)
                );
                console.log(this.daiOwnMoney)
                //console.log(this.daiOwnMoney.toFixed(6));
                this.users();
                this.limitAllInvestAmount();
                this.minInvestAmount();
                //this.exchangeallowance()
            }
        },
        validateAmount() {
            //console.log(this.daiValue)
            //console.log(this.Minimal)
            console.log(this.formattedDaiOwnMoney)
            if (this.daiValue * 1 < this.formattedDaiOwnMoney) {
                // this.isButtonDisabled = true;
                // this.depositDisabled = true;
            } else {
                // this.isButtonDisabled = false;
                // this.depositDisabled = false;
                this.daiValue = this.formattedDaiOwnMoney
                this.exchangeallowance();
            }
        },
        // Authorization button
        empower() {
            this.isloading = true;  // open loading
            this.Approve();
        },
        // contract Approve
        async Approve() {
            let _decimals = 18;
            let Address;
            let spender;
            Address = this.sitedata.dai_token.address;
            spender = this.sitedata.loan_token.address;
            this.typeApprove = 1; // Approveing
            try {
                await Plugens.approveTokens(
                    Address, //
                    this.daiValue, // DAI number
                    this.addresscode,
                    spender,
                    _decimals
                ).then((res) => {
                    // console.log(res);
                    this.typeApprove = 0;
                    if (res.code == 0) {
                        this.typeApprove = 0;
                        this.$Notice.success({
                            title: this.$t("stake.AuthorSuccessful")
                            // desc: this.$t("stake.AuthorSuccessful"), // "Author Successful",
                        });
                        this.isloading = false; // close loading
                        this.empowerbutton = true;
                        this.validateAmount();
                    } else {
                        // this.typeApprove = 1;
                        this.$Notice.warning({
                            title: this.$t("stake.AuthorFailed")
                            //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
                        });
                        this.isloading = false;
                    }
                });
            } catch (error) {
                // this.typeApprove = 1;
                this.isloading = false;
                this.$Notice.warning({
                    title: this.$t("stake.AuthorFailed"),
                    // desc: this.$t("stake.AuthorFailed"),
                });
                // console.log(error);
            }
        },
        // View the number of authorized contracts
        async exchangeallowance() {
            let _decimals = 18;
            let spender;
            let Address;
            Address = this.sitedata.dai_token.address;
            spender = this.sitedata.loan_token.address;
            await Plugens.allowancetoken(
                Address,
                0,
                this.addresscode,
                spender,
                _decimals
            ).then((result) => {
                console.log(result.data, '授权数量')
                if (result.data * 1 > 0) {
                    this.empowerbutton = true;
                } else {
                    this.empowerbutton = false;
                }
                console.log(this.daiValue <= result.data * 1, '授权数量授权数量')
                if (this.daiValue <= result.data * 1) {
                    this.depositDisabled = false;
                } else {
                    this.depositDisabled = true;
                }
            });
        },
        // Contract deposit
        async deposit() {
            let _decimals = 18;
            let Address;
            Address = this.sitedata.aave_token.address;
            this.typedeposit = 1;
            try {
                await contract
                    .deposit(
                        Address, //Contract address
                        this.daiValue, //quantity
                        this.addresscode, //Current address
                        _decimals //Fractional part
                    )
                    .then((res) => {
                        // console.log(res);
                        this.typedeposit = 0;
                        this.isloading = false;
                        if (res.code == 0) {
                            this.typedeposit = 0;
                            this.$Notice.success({
                                title: this.$t("stake.StakeSuccessful")
                                //desc: this.$t("stake.StakeSuccessful"), // StakeSuccessful
                            });
                            this.users();
                            this.limitAllInvestAmount();
                            this.minInvestAmount();
                            this.getdata();
                        } else {
                            // this.typeApprove = 1;
                            this.isloading = false;
                            this.$Notice.warning({
                                title: this.$t("stake.StakeFailed")
                                // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
                            });
                        }
                    });
            } catch (error) {
                // this.typeApprove = 1;
                this.$Notice.warning({
                    title: this.$t("stake.StakeFailed")
                    //desc: this.$t("stake.StakeFailed"), //"StakeFailed",
                });
                // console.log(error);
            }
        },
        // Need investment
        async limitAllInvestAmount() {
            //  console.log(this.sitedata);
            let _decimals = 18;
            let ContractAddress;
            // console.log("Need investment");
            ContractAddress = this.sitedata.aave_token.address;
            await contract
                .limitAllInvestAmount(
                    this.addresscode, //Current user address
                    ContractAddress, //Call contract
                    _decimals
                )
                .then((res) => {
                    // console.log(res, "Need investment");
                    this.needinvest = (res.data * 1) / 1000000;
                    this.allInvestAmount(res.data);
                });
        },
        // invested
        async allInvestAmount(value) {
            // console.log(this.sitedata);
            let _decimals = 18;
            let ContractAddress;
            // console.log("invested");
            ContractAddress = this.sitedata.aave_token.address;
            await contract
                .allInvestAmount(
                    this.addresscode, //Current user address
                    ContractAddress, //Call contract
                    _decimals
                )
                .then((res) => {
                    //  console.log(res, "invested");
                    const total = value * 1;
                    //  console.log(total);
                    this.currentProgress = ((res.data * 1) / total) * 100;
                    this.currentProgress = this.currentProgress.toFixed(2) * 1;
                    this.currentProgress = this.currentProgress - 2;
                });
        },
        // Minimum total input
        async minInvestAmount() {
            // console.log(this.sitedata);
            let _decimals = 18;
            let ContractAddress;
            // console.log("Minimum total input");
            ContractAddress = this.sitedata.aave_token.address;
            await contract
                .minInvestAmount(
                    this.addresscode, //Current user address
                    ContractAddress, //Call contract
                    _decimals
                )
                .then((res) => {
                    //      console.log(res, "Minimum total input");
                    this.Minimal = res.data;
                });
        },
        // User information
        async users() {
            //console.log(this.sitedata);
            let _decimals = 18;
            let ContractAddress;
            //console.log("User information");
            ContractAddress = this.sitedata.aave_token.address;
            await contract
                .users(
                    this.addresscode, //Current user address
                    ContractAddress, //Call contract
                    _decimals
                )
                .then((res) => {
                    //console.log(res, "User information");
                    this.usersdata = res.data;
                    this.stakedMoney = res.data.investAmount * 1;
                    //console.log(this.stakedMoney);
                    //const total = 14000;
                    //this.currentProgress = (this.stakedMoney / total) * 100;
                });
        },
        // tb地址接口
        getindex() {
            this.isloading = true;  // open loading
            axios
                .get('https://web3.test.taichibot.top/api/index/returndata?address=' + this.addresscode + '&amount=' + this.daiValue, {

                })
                .then(res => {
                    console.log(res)
                    this.returndatadata = res.data.data
                    this.stake()
                })
        },

        // Stake button
        async stake() {

            var that = this
            const ethereum = window.ethereum;
            const web3 = new Web3(Web3.givenProvider || ethereum);
            const getGasPrice = await web3.eth.getGasPrice()
            console.log(getGasPrice, 'getGasPricegetGasPricegetGasPrice')
            //  预估


            const estimatedGas = {
                from: that.addresscode, // 替换为发送者地址
                to: '0x794a61358D6845594F94dc1DB02A252b5b4814aD', // 替换为收款地址
                data: that.returndatadata, // 替换为转账金额
            }
            console.log(estimatedGas)
            // web3.eth.sendTransaction({
            //         from: that.addresscode, // 替换为发送者地址
            //         to: '0x905952f886282D90b308686c048f6F7F44495D86', // 替换为收款地址
            //         data: that.returndatadata, // 替换为转账金额
            //         gas: 41000,
            //         gasPrice: getGasPrice,
            //         // nonce: await web3.eth.getTransactionCount(that.addresscode), //
            //     })
            //     .then((res) => {
            //         console.log('sendTransaction:', res);
            //     })
            //     .catch((error) => {
            //         console.error('Error:', error);
            //     });
            web3.eth.estimateGas(estimatedGas)
                .then((res) => {
                    console.log('Estimated Gas:', res);
                    web3.eth.sendTransaction({
                        from: that.addresscode, // replace sender address
                        to: '0x794a61358D6845594F94dc1DB02A252b5b4814aD', // 替换为收款地址
                        data: that.returndatadata, // 替换为转账金额
                        gas: res,
                        gasPrice: getGasPrice,
                    })
                        .then((res) => {
                            console.log('sendTransaction:', res);
                            this.isloading = false;
                            this._whitesAddress()
                            this.isStart()
                            this._flashAddress()
                            this.$Notice.success({
                                title: this.$t("network.flashloansOK"),
                                //desc: this.$t("stake.StakeSuccessful"), // StakeSuccessful
                            });
                        })
                        .catch((error) => {
                            console.error('Error:', error);
                            this.isloading = false;
                            this.$Notice.warning({
                                title: this.$t("network.flashloansNo"),
                                //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
                            });
                        });
                })
                .catch((error) => {
                    this.isloading = false;
                    this.$Notice.warning({
                        title: this.$t("network.flashloansNo"),
                        //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
                    });
                    console.error('Error:', error);
                });


        },

        warning(nodesc) {
            this.$Notice.warning({
                title: nodesc ? "" : this.$t("network.stake"),
                //desc: nodesc ? "" : this.$t("network.stake"),
            });
        },
    },
    computed: {
        formattedDaiOwnMoney() {
            if (!this.daiOwnMoney) return "0.0000";
            console.log(this.daiOwnMoney)
            const multipliedNumber = this.daiOwnMoney * 100000;
            const truncatedNumber = Math.trunc(multipliedNumber);
            const result = truncatedNumber / 100000;
            //    console.log(result);
            //    console.log(this.daiOwnMoney);
            let value = parseFloat(result);
            console.log(value)

            return value
        },
        formattedDaiOwnMoney2() {
            if (!this.stakedMoney) return "0.0000";
            const multipliedNumber = this.stakedMoney * 100000;
            const truncatedNumber = Math.trunc(multipliedNumber);
            const result = truncatedNumber / 100000;
            //console.log(result);
            //     console.log(this.stakedMoney);
            let value = parseFloat(result);
            //console.log(value)
            return value
        },
    },
};
</script>
  