const ko = {
  network: {
    tips: "선택한 네트워크는 현재 지원되지 않습니다!Matic (Polygon) 네트워크를 선택해주세요!",
    ev: `MetaMask, Trust Wallet, imToken, TokenPocket 등의 지갑 소프트웨어 중 하나를 설치했는지 확인해주세요.`,
    copyight: "FlashMonkey",
    all: "Max",
    bolck: "Dai Stablecoin",
    law: `투자자 주의: 이 분산 애플리케이션(DApp)은 불확실한 투자 위험을 
    내포하고 있습니다. 각 국가의 정책 및 규제는 블록체인 기술과 관련 
    투자에 대해 엄격한 제한과 통제를 가할 수 있습니다. 더욱이, 디지털 
    화폐의 가격은 매우 불안정하여 투자자가 큰 투자 위험을 감수할 수 있습니다. 
    투자에 참여하기 전 투자자는 투자 위험을 충분히 이해하고 평가해야 하며, 
    신중한 결정을 내려야 합니다. 투자자가 섣불리 투자에 동참하여 돌이킬 수 
    없는 경제적 손실을 입는 것을 방지하기 위해, 우리는 투자자가 이성적으로 
    투자할 것을 강력히 권장합니다.`,
    protocol: `이 분산 애플리케이션(DApp)에는 불확실한 투자 위험이 내재하고 있습니다. 사용하기 전에, 리스크 경고와 개인정보처리방침을 반드시 주의 깊게 읽고 충분히 이해하십시오.`,
    more: "세부사항",
    media: "관련 소셜 미디어",
    stake: "2023년 11월 6일 전 세계 오픈, 텔레그램과 트위터를 팔로우하세요!",
    withdraw: "2023년 11월 6일 전 세계 오픈, 텔레그램과 트위터를 팔로우하세요!",
    Reinvest: "2023년 11월 6일 전 세계 오픈, 텔레그램과 트위터를 팔로우하세요!",
    Getreward:
      "2023년 11월 6일 전 세계 오픈, 텔레그램과 트위터를 팔로우하세요!",
    linkwallet: "지갑 연결",
    notification: "이 기능은 곧 출시됩니다! 커뮤니티 공지를 주목하세요!",
    flashloansOK:'시작 성공! ',
    flashloansNo:'시작 실패',
    flashloansOnce:'1회 체험 기회를 얻게 되어 축하합니다',
    flashloansError:'죄송합니다, 체험 기회가 이미 사용되었습니다',
    falocnerror:'Falcon™ V2 테스트 자격이 승인되지 않았습니다!',
    falocninfo:'Falcon™ V2 레벨 승인을 받은 플레이어를 대상으로 합니다! 현재 공개 테스트 단계에 있습니다!!'
  },
  leavel: {
    0:"씨앗",
    1: "블랙커런트",
    2: "체리",
    3: "드래곤 프룻",
    4: "헤이즐넛",
    5: "노니",
    6: "감",
    7: " 트러플",
  },
  top: {
    Account: "계정",
  },
  stake: {
    Balance: "당신의 잔액",
    Total: "총 스테이크",
    Appoove: "승인",
    Stake: "스테이크",
    Appooveing: "인증 중",
    AuthorSuccessful: "인증 성공",
    AuthorFailed: "인증 실패",
    Stakeing: "입금 중",
    StakeSuccessful: "입금 성공",
    StakeFailed: "입금 실패",
    StakeInfo: "미만일 수 없습니다:",
  },
  menu: {
    Home: "홈",
    Language: "언어",
    Document: "백서",
    Contract: "계약",
    Watch: "시계",
    Coming: "곧 출시",
    EvDao: "FM-DAO",
    Flashloan: "플래시론 시작",
    Developer:"개발자 문서",
    Launch:"Launch",
    xNumber:"남은",
    xTips:"자동 처리",
    LogiV2:"Falcon™ V2"

  },
  Withdraw: {
    Withdraw: "인출",
    Withdrawable: "인출 가능",
    Earnings: "수익",
    Brokerage: "브로커리지 금액",
    Reinvest: "재투자",
    Getreward: "보상 받기",
    Processing: "처리 중",
    unsuccessfule: "성공하지 못한",
    successful: "시스템 업데이트 중!",
    WithdrawInfo1: "인출 수량을 입력해 주세요",
    WithdrawInfo2: "수량이 부족합니다",
  },
  Team: {
    innver: "Innver",
    Teamstaked: "팀 스테이크",
    leavel: "레벨",
    Team: "팀",
    Bind: "관계 바인딩",
    info: "바인딩 실패！",
    Binding: "바인딩 중",
    BindingSuccessful: "바인딩 성공",
    BindingFailed: "바인딩 실패",
    Bindinfo: "초대인의 지갑 주소를 입력하세요",
  },
  Share: {
    Invitelink: "초대 링크",
    Intips:
      "Dapp 초대장은 현재 사용할 수 없습니다. 최신 커뮤니티 업데이트를 기다려 주세요.",
    copyTitle: "초대 링크",
    copyText: "초대 링크 복사 성공!",
    copyCommnad: "링크 복사",
  },
  Contract: {
    contractaddress: "계약 주소",
    Copylink: "Polygonscan",
    CopylinkText: "polygonscan으로 가기!",
  },
  Wathch: {
    watchonlywallet: "시계 전용 지갑",
    addwallet: "시계 전용 지갑 추가",
    add: "추가",
    Leavel: "레벨",
    share: "공유",
    fnOpen: "이 지갑은 이 기능에 아직 초대되지 않았습니다!",
    nullString: "지갑 주소를 입력하세요!",
    addok:"추가 성공",
    addError:"추가 실패",
    addisExists:"이 주소는 이미 존재합니다",
    addlist:"관찰 목록",
  },
};
export default ko;
