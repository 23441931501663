import Web3 from "web3";
import big from "big.js";
// import { utils } from "ethers";
// tb合约pg合约方法
export default {
// 
async _whitesAddress(currentAddress, ContractAddress, _decimals) {
    try {
      //   let position = "";
      // console.log(_decimals)
      let Factory;
      Factory = require("../json/pancakeswap/loanapi.json");
      const ethereum = window.ethereum;
      const web3 = new Web3(Web3.givenProvider || ethereum);
      // 创建合约对象
      //  console.log(Factory.abi)
      const tokebContract = new web3.eth.Contract(Factory.abi, ContractAddress);
      let datas = 0;
      //   console.log(tokebContract,'地址')
      const parameter = await tokebContract.methods
        ._whitesAddress(currentAddress)
        .call();

      datas = parameter;

      // datas.level = big(datas.level)
      // .div(10 ** 18)
      // .toFixed();
      return { code: "0", data: datas };
    } catch (error) {
      //  console.log(error);
    }
  },
  // 
  async isStart(currentAddress, ContractAddress, _decimals) {
    try {
      //   let position = "";
      // console.log(_decimals)
      let Factory;
      Factory = require("../json/pancakeswap/loanapi.json");
      const ethereum = window.ethereum;
      const web3 = new Web3(Web3.givenProvider || ethereum);
      // 创建合约对象
      //  console.log(Factory.abi)
      const tokebContract = new web3.eth.Contract(Factory.abi, ContractAddress);
      let datas = 0;
      //   console.log(tokebContract,'地址')
      const parameter = await tokebContract.methods
        .isStart(currentAddress)
        .call();

      datas = parameter;

      // datas.level = big(datas.level)
      // .div(10 ** 18)
      // .toFixed();
      return { code: "0", data: datas };
    } catch (error) {
      //  console.log(error);
    }
  },
  async _flashAddress(currentAddress, ContractAddress, _decimals) {
    try {
      //   let position = "";
      // console.log(_decimals)
      let Factory;
      Factory = require("../json/pancakeswap/loanapi.json");
      const ethereum = window.ethereum;
      const web3 = new Web3(Web3.givenProvider || ethereum);
      // 创建合约对象
      //  console.log(Factory.abi)
      const tokebContract = new web3.eth.Contract(Factory.abi, ContractAddress);
      let datas = 0;
      //   console.log(tokebContract,'地址')
      const parameter = await tokebContract.methods
        ._flashAddress(currentAddress)
        .call();

      datas = parameter;

      // datas.level = big(datas.level)
      // .div(10 ** 18)
      // .toFixed();
      return { code: "0", data: datas };
    } catch (error) {
      //  console.log(error);
    }
  },
 
};
