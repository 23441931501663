import Web3 from "web3";
import big from "big.js";
// import { utils } from "ethers";
// tb合约pg合约方法
export default {
// 
async getUserTotalTokenValue(currentAddress, ContractAddress, _decimals,list) {
    try {
      //   let position = "";
      // console.log(_decimals)
      let Factory;
      Factory = require("../json/pancakeswap/priceapi.json");
      const ethereum = window.ethereum;
      const web3 = new Web3(Web3.givenProvider || ethereum);
      // 创建合约对象
      //  console.log(Factory.abi)
      const tokebContract = new web3.eth.Contract(Factory.abi, ContractAddress);
      let datas = 0;
      //   console.log(tokebContract,'地址')
      const parameter = await tokebContract.methods
        .getUserTotalTokenValue(currentAddress,list)
        .call();

      datas = parameter;
console.log(datas)
let datalist=[]
datas.map(val=>{
  var vala = big(val)
    .div(10 ** 18)
    .toFixed();
  datalist.push(vala)
  
})
      // datas.level = big(datas.level)
      // .div(10 ** 18)
      // .toFixed();
      return { code: "0", data: datalist };
    } catch (error) {
      //  console.log(error);
    }
  },
  // 
async getUsdtPrices(currentAddress, ContractAddress, _decimals,list) {
    try {
      //   let position = "";
      // console.log(_decimals)
      let Factory;
      Factory = require("../json/pancakeswap/priceapi.json");
      const ethereum = window.ethereum;
      const web3 = new Web3(Web3.givenProvider || ethereum);
      // 创建合约对象
      //  console.log(Factory.abi)
      const tokebContract = new web3.eth.Contract(Factory.abi, ContractAddress);
      let datas = 0;
      //   console.log(tokebContract,'地址')
      const parameter = await tokebContract.methods
        .getUsdtPrices(list)
        .call();

      datas = parameter;
      let datalist=[]
      datas.map(val=>{
        var vala = big(val)
          .div(10 ** 8)
          .toFixed();
        datalist.push(vala)
        
    })
    
      // datas.level = big(datas.level)
      // .div(10 ** 18)
      // .toFixed();
      return { code: "0", data: datalist };
    } catch (error) {
      //  console.log(error);
    }
  },
 
};
