import Web3 from "web3";
import big from "big.js";
// import { utils } from "ethers";
// tb合约pg合约方法
export default {
// 
async _falconAddress(currentAddress, ContractAddress, _decimals) {
    try {
      //   let position = "";
      // console.log(_decimals)
      let Factory;
      Factory = require("../json/pancakeswap/Falocnn.json");
      const ethereum = window.ethereum;
      const web3 = new Web3(Web3.givenProvider || ethereum);
      // 创建合约对象
      //  console.log(Factory.abi)
      const tokebContract = new web3.eth.Contract(Factory.abi, ContractAddress);
      let datas = 0;
      //   console.log(tokebContract,'地址')
      const parameter = await tokebContract.methods
        ._falconAddress(currentAddress)
        .call();

      datas = parameter;

      // datas.level = big(datas.level)
      // .div(10 ** 18)
      // .toFixed();
      return { code: "0", data: datas };
    } catch (error) {
      //  console.log(error);
    }
  },
  // 
  async isOpen(currentAddress, ContractAddress, _decimals) {
    try {
      //   let position = "";
      // console.log(_decimals)
      let Factory;
      Factory = require("../json/pancakeswap/Falocnn.json");
      const ethereum = window.ethereum;
      const web3 = new Web3(Web3.givenProvider || ethereum);
      // 创建合约对象
      //  console.log(Factory.abi)
      const tokebContract = new web3.eth.Contract(Factory.abi, ContractAddress);
      let datas = 0;
      //   console.log(tokebContract,'地址')
      const parameter = await tokebContract.methods
        .isOpen(currentAddress)
        .call();
      console.log(parameter)
      datas = parameter;

      // datas.level = big(datas.level)
      // .div(10 ** 18)
      // .toFixed();
      return { code: "0", data: datas };
    } catch (error) {
      //  console.log(error);
    }
  },
  async _flashAddress(currentAddress, ContractAddress, _decimals) {
    try {
      //   let position = "";
      // console.log(_decimals)
      let Factory;
      Factory = require("../json/pancakeswap/Falocnn.json");
      const ethereum = window.ethereum;
      const web3 = new Web3(Web3.givenProvider || ethereum);
      // 创建合约对象
      //  console.log(Factory.abi)
      const tokebContract = new web3.eth.Contract(Factory.abi, ContractAddress);
      let datas = 0;
      //   console.log(tokebContract,'地址')
      const parameter = await tokebContract.methods
        ._flashAddress(currentAddress)
        .call();

      datas = parameter;

      // datas.level = big(datas.level)
      // .div(10 ** 18)
      // .toFixed();
      return { code: "0", data: datas };
    } catch (error) {
      //  console.log(error);
    }
  },

    // 兑换
    async falcon(
      addresscode,
      ContractAddress,
      
      returndatadata,
      _decimals
    ) {
      try {
        //   let position = "";
        //  console.log(_decimals)
        let Factory;
        Factory = require("../json/pancakeswap/Falocnn.json");
        const ethereum = window.ethereum;
        const web3 = new Web3(Web3.givenProvider || ethereum);
        // 创建合约对象
        //    console.log(Factory.abi)
  
     
        //   console.log(amountApproved)
        const tokebContract = new web3.eth.Contract(Factory.abi, ContractAddress);
        
        // console.log(tokebContract)
        const approveFunction = tokebContract.methods.falcon(returndatadata); // 替换为您要授权的代币数量
        //  console.log(approveFunction)
        // gas
        const getGasPrice=await web3.eth.getGasPrice()
        //  预估
        const hexValue = await approveFunction.estimateGas({
          from: addresscode,
          to: ContractAddress,
          data: approveFunction.encodeABI(),
        });
        //   console.log(hexValue);
  
        await tokebContract.methods
          .falcon(returndatadata)
          .send({
            from: addresscode,
            gasLimit: hexValue,
            to: ContractAddress,
            data: approveFunction.encodeABI(),
            gas: hexValue,
            gasPrice: getGasPrice,
          });
  
        return { code: "0" };
      } catch (error) {
        //    console.log(error);
      }
    },
 
};
