import Web3 from "web3";
import { ethers } from 'ethers';

// DAI合约的通用ERC-20 ABI和地址
const DAI_ABI = [
  {
    constant: true,
    inputs: [{ name: "_owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "balance", type: "uint256" }],
    type: "function",
  },
];
const DAI_CONTRACT_ADDRESS = "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063";

const web3 = new Web3(window.ethereum || "https://polygon-rpc.com");

async function connectWallet() {
  try {
    await window.ethereum.enable();
    const accounts = await web3.eth.getAccounts();
    console.log(accounts,'accountsaccountsaccountsaccounts')
    return accounts.length > 0 ? accounts[0] : null;
  } catch (error) {
    console.error("Error connecting wallet:", error);
    return null;
  }
}

async function getDaiBalance(address) {
  try {
    // const daiContract = new web3.eth.Contract(DAI_ABI, DAI_CONTRACT_ADDRESS);
    // const balanceWei = await daiContract.methods.balanceOf(address).call();
    // console.log(balanceWei,'balanceWeibalanceWeibalanceWei')
    // return web3.utils.fromWei(balanceWei, "ether");


    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(DAI_CONTRACT_ADDRESS, DAI_ABI, provider);
    const balance = await contract.balanceOf(address)
    console.log(balance.toString(),'getDaiBalancegetDaiBalancegetDaiBalance')
    return balance.toString()
  } catch (error) {
    console.error("Error fetching DAI balance:", error);
    return null;
  }
}

export default {
  connectWallet,
  getDaiBalance,
};
